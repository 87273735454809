import { PERMISSION_ACTION } from "helpers/enums";

// Should match QA alpha_actions in dozer
export const ALPHA_PERMISSIONS = [
  PERMISSION_ACTION.AI_CHATBOT,
  PERMISSION_ACTION.PROJECT_FORECASTING,
];

// Should match QA beta_actions in dozer
export const BETA_PERMISSIONS = [
  PERMISSION_ACTION.BORROWER_PORTAL,
  PERMISSION_ACTION.HUD_FEATURES,
  PERMISSION_ACTION.RULES_REDESIGN_CLERICAL,
];

export const CREATION_FEATURES = [
  PERMISSION_ACTION.CREATE_DRAW,
  PERMISSION_ACTION.CREATE_EDIT_ORGANIZATION,
  PERMISSION_ACTION.CREATE_PROJECT,
];

export const DEFAULT_DISABLED_DEVELOPER_FEATURES = [
  PERMISSION_ACTION.LOCK_PREVIOUS_DRAWS,
];

export const DEFAULT_DISABLED_LENDER_FEATURES = [
  PERMISSION_ACTION.SKIP_MFA,
  PERMISSION_ACTION.STAMP_DOCUMENTS,
];

export const DEFAULT_DISABLED_FEATURES = [
  PERMISSION_ACTION.AI_CHATBOT,
  PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM,
  PERMISSION_ACTION.DRAW_OVERVIEW_PAGE,
  PERMISSION_ACTION.EDIT_DRAW_STATUS,
  PERMISSION_ACTION.GENERATE_API_TOKEN,
  PERMISSION_ACTION.HUD_FEATURES,
  PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW,
  PERMISSION_ACTION.LIMIT_TRACKED_AGREEMENT_WARNINGS,
  PERMISSION_ACTION.LINE_ITEM_DRAWER,
  PERMISSION_ACTION.PROJECT_FORECASTING,
  PERMISSION_ACTION.RULES_REDESIGN_CLERICAL,
  PERMISSION_ACTION.SUPER_LINE_ITEMS,
  PERMISSION_ACTION.TURN_OFF_TILE_VIEW,
  PERMISSION_ACTION.USE_COBRANDING,
  PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING,
];

// Should match QA developer_only_actions in dozer
export const DEVELOPER_FEATURES = [
  PERMISSION_ACTION.ADVANCED_REPORT_DEVELOPER,
  PERMISSION_ACTION.AGREEMENT_MANAGEMENT,
  PERMISSION_ACTION.TRACK_COST_TO_AGREEMENTS,
  PERMISSION_ACTION.LIMIT_TRACKED_AGREEMENT_WARNINGS,
  PERMISSION_ACTION.EDIT_AMOUNT_REQUESTED,
  PERMISSION_ACTION.SUBMIT_DRAW,
  PERMISSION_ACTION.UPDATE_REQUESTED_AMOUNT_WITH_DOCUMENTATION,
];

export const DOCUMENT_FEATURES = [
  PERMISSION_ACTION.ASSIGN_DOCUMENTS,
  PERMISSION_ACTION.ASSIGN_MULTIPLE_LINE_ITEM_COST_CODES,
  PERMISSION_ACTION.AUTO_CALCULATE_RETAINAGE,
  PERMISSION_ACTION.DOWNLOAD_DOCUMENT,
  PERMISSION_ACTION.LINE_ITEM_DRAWER,
  PERMISSION_ACTION.PAYMENT_TRACKING,
  PERMISSION_ACTION.SHOW_PARSED_FIELDS,
  PERMISSION_ACTION.SHOW_INVOICE_RETAINAGE,
  PERMISSION_ACTION.STAMP_DOCUMENTS,
  PERMISSION_ACTION.SUBCONTRACTOR_FORM,
  PERMISSION_ACTION.TIERED_DOCUMENT_REVIEWERS,
  PERMISSION_ACTION.UPDATE_DOCUMENT,
  PERMISSION_ACTION.UPLOAD_DOCUMENT,
];

export const FUNDING_FEATURES = [
  PERMISSION_ACTION.AUTO_ALLOCATE,
  PERMISSION_ACTION.USES_OF_FUNDS,
];

export const GENERAL_FEATURES = [
  PERMISSION_ACTION.ACCESS_FUNDING_SOURCES,
  PERMISSION_ACTION.ACCESS_STAKEHOLDERS,
  PERMISSION_ACTION.ADVANCED_REPORT,
  PERMISSION_ACTION.AI_CHATBOT,
  PERMISSION_ACTION.ALLOW_MULTIPLE_UNFUNDED_BORROWER_DRAWS,
  PERMISSION_ACTION.CONFIGURE_ORGANIZATION,
  PERMISSION_ACTION.DELETE_DOCUMENTS,
  PERMISSION_ACTION.DELETE_DRAW,
  PERMISSION_ACTION.DELETE_ORGANIZATION,
  PERMISSION_ACTION.DELETE_PROJECT,
  PERMISSION_ACTION.DO_NOT_VOID_DRAW_APPROVALS,
  PERMISSION_ACTION.DRAW_ASSESSMENT_QUESTIONS_FORM,
  PERMISSION_ACTION.DRAW_OVERVIEW_PAGE,
  PERMISSION_ACTION.EDIT_DRAW_STATUS,
  PERMISSION_ACTION.EDIT_BUDGET,
  PERMISSION_ACTION.EDIT_ORIGINAL_BUDGET_AMOUNT,
  PERMISSION_ACTION.EDIT_PROJECT_SETTINGS,
  PERMISSION_ACTION.EDIT_USER_ACCESS,
  PERMISSION_ACTION.ENABLE_TRACKED_COSTS_ACROSS_VENDORS,
  PERMISSION_ACTION.GENERATE_API_TOKEN,
  PERMISSION_ACTION.HUD_FEATURES,
  PERMISSION_ACTION.INSPECTION_REPORT_WORKFLOW,
  PERMISSION_ACTION.JOB_COST_CODES,
  PERMISSION_ACTION.LOCK_PREVIOUS_DRAWS,
  PERMISSION_ACTION.MAKE_PROJECT_BUDGET_ADJUSTMENTS,
  PERMISSION_ACTION.MANAGE_USER,
  PERMISSION_ACTION.PROJECT_FORECASTING,
  PERMISSION_ACTION.REPORT_GENERATION,
  PERMISSION_ACTION.RULES_REDESIGN_CLERICAL,
  PERMISSION_ACTION.RUN_REPORT,
  PERMISSION_ACTION.SAVE_TABLE_VIEWS,
  PERMISSION_ACTION.TASK_MANAGEMENT,
  PERMISSION_ACTION.DEPENDENCY_TRACKING,
  PERMISSION_ACTION.SKIP_MFA,
  PERMISSION_ACTION.SUPER_LINE_ITEMS,
  PERMISSION_ACTION.TEAM_MANAGEMENT,
  PERMISSION_ACTION.TURN_OFF_TILE_VIEW,
  PERMISSION_ACTION.USE_COBRANDING,
  PERMISSION_ACTION.USE_ENHANCED_LINE_ITEM_REPORTING,
  PERMISSION_ACTION.VENDOR_COST_CODES,
  PERMISSION_ACTION.VIEW_ALL_PROJECTS,
  PERMISSION_ACTION.VIEW_ORGANIZATIONS,
];

export const ACCOUNTS_PAYABLE_FEATURES = [
  PERMISSION_ACTION.AVID_AP_INTEGRATION,
  PERMISSION_ACTION.NEXUS_INTEGRATION,
  PERMISSION_ACTION.VISION_DELTEK_AP_INTEGRATION,
  PERMISSION_ACTION.YARDI_INTEGRATION,
  PERMISSION_ACTION.PULL_DATA_FROM_YARDI,
  PERMISSION_ACTION.PUSH_TO_BILL,
  PERMISSION_ACTION.PUSH_TO_MRI,
  PERMISSION_ACTION.PUSH_TO_QUICK_BOOKS_ONLINE,
];

export const ACCOUNTS_PAYABLE_OPTIONS = [
  PERMISSION_ACTION.SEND_RETAINAGE_TO_ACCOUNTS_PAYABLE,
  PERMISSION_ACTION.INCLUDE_CODE_INFO_FOR_MRI,
  PERMISSION_ACTION.YARDI_JOB_COST_MODULE,
  PERMISSION_ACTION.YARDI_PULL_INDIVIDUALIZE_JOURNAL_ENTRIES,
];

// Should match QA lender_only_actions in dozer
export const LENDER_FEATURES = [
  PERMISSION_ACTION.ADVANCED_REPORT_LENDER,
  PERMISSION_ACTION.BORROWER_PORTAL,
  PERMISSION_ACTION.IMPORT_DRAW_SUMMARY,
  PERMISSION_ACTION.INVOICE_SUMMARY_TAB,
];

export const SPECIAL_MESSAGE_PERMISSIONS = [
  PERMISSION_ACTION.EDIT_BUDGET,
  PERMISSION_ACTION.LOCK_PREVIOUS_DRAWS,
];

export const NON_ORG_PERMISSIONS = [PERMISSION_ACTION.USER_PROJECT_ACCESS];
