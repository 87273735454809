import React from "react";
import PropTypes from "prop-types";
import {
  Button,
  Checkbox,
  Heading,
  Icon,
  Link,
  Pane,
  Paragraph,
  Table,
  Text,
  TickCircleIcon,
} from "components/materials";
import { majorScale, minorScale, t } from "helpers/utilities";
import { PERMISSION_ACTION } from "helpers/enums";
import { includes, xor, values, camelCase } from "lodash";
import {
  ACCOUNTS_PAYABLE_FEATURES,
  ACCOUNTS_PAYABLE_OPTIONS,
  ALPHA_PERMISSIONS,
  BETA_PERMISSIONS,
  CREATION_FEATURES,
  DEVELOPER_FEATURES,
  DOCUMENT_FEATURES,
  FUNDING_FEATURES,
  GENERAL_FEATURES,
  LENDER_FEATURES,
  NON_ORG_PERMISSIONS,
} from "helpers/permissionSectionHelpers";
import { FeatureTags } from "./OrganizationPermissionsForm";

const ALL_FEATURES = values(PERMISSION_ACTION).filter(
  (permission) => !includes(NON_ORG_PERMISSIONS, permission)
);

const permissionSets = [
  { label: "Accounts Payable", permissions: ACCOUNTS_PAYABLE_FEATURES },
  { label: "Accounts Payable Options", permissions: ACCOUNTS_PAYABLE_OPTIONS },
  { label: "Creation", permissions: CREATION_FEATURES },
  { label: "Developer", permissions: DEVELOPER_FEATURES },
  { label: "Document", permissions: DOCUMENT_FEATURES },
  { label: "Funding", permissions: FUNDING_FEATURES },
  { label: "General", permissions: GENERAL_FEATURES },
  { label: "Lender", permissions: LENDER_FEATURES },
  { label: "Alpha", permissions: ALPHA_PERMISSIONS },
  { label: "Beta", permissions: BETA_PERMISSIONS },
  { label: "All", permissions: ALL_FEATURES },
  { label: "None", permissions: [] },
];

export function OrganizationPermissionsTable({ organizations }) {
  const [visiblePermissions, setVisiblePermissions] =
    React.useState(ALL_FEATURES);
  const [showIndividualToggles, setShowIndividualToggles] =
    React.useState(false);

  return (
    <React.Fragment>
      <Pane marginY={majorScale(2)}>
        <Heading marginY={majorScale(1)}>
          Select permissions shown in table
        </Heading>
        <Pane marginLeft={majorScale(3)}>
          <Text fontWeight={500} marginRight={majorScale(1)}>
            By set:
          </Text>
          {permissionSets.map(({ label, permissions }) => (
            <Button
              key={label}
              marginRight={minorScale(3)}
              onClick={() => setVisiblePermissions(permissions)}
            >
              {label}
            </Button>
          ))}
          <Button onClick={() => setShowIndividualToggles((show) => !show)}>
            {showIndividualToggles
              ? "Hide Individual Toggles"
              : "Show Individual Toggles"}
          </Button>
        </Pane>
        <Pane marginLeft={majorScale(3)} marginTop={majorScale(1)}>
          {showIndividualToggles &&
            permissionSets.map(({ label, permissions }) =>
              label !== "All" && permissions.length > 0 ? (
                <React.Fragment key={label}>
                  <Paragraph fontWeight={500} width={100}>
                    {label}:
                  </Paragraph>
                  <Pane
                    display="flex"
                    flexWrap="wrap"
                    marginLeft={majorScale(3)}
                  >
                    {permissions.map((action) => (
                      <Pane
                        alignItems="flex-start"
                        display="flex"
                        height={40}
                        width={200}
                      >
                        <Checkbox
                          key={action}
                          label={t(`permissions.${action}`)}
                          checked={includes(visiblePermissions, action)}
                          onChange={() =>
                            setVisiblePermissions((oldVisiblePermissions) =>
                              xor(oldVisiblePermissions, [action])
                            )
                          }
                        />
                      </Pane>
                    ))}
                  </Pane>
                </React.Fragment>
              ) : null
            )}
        </Pane>
      </Pane>
      <Pane overflowX="auto" height={700} overflowY="auto">
        <Table
          is="table"
          border="default"
          width={(visiblePermissions.length + 1) * 120}
        >
          <Table.Head
            is="thead"
            height={80}
            marginTop={-2}
            position="sticky"
            top={0}
            zIndex={5}
          >
            <Table.TextHeaderCell
              is="th"
              position="sticky"
              left={0}
              background="#F5F6F7"
              borderRight
            >
              Organization
            </Table.TextHeaderCell>
            {visiblePermissions.map((action) => (
              <Table.TextHeaderCell key={action} textAlign="center">
                {t(`permissions.${action}`)}
                <FeatureTags permission={action} />
              </Table.TextHeaderCell>
            ))}
          </Table.Head>
          <Table.Body is="tbody">
            {organizations.map(({ id, name, permissionConfig }) => (
              <Table.Row is="tr" key={id}>
                <Table.TextCell
                  is="td"
                  background="white"
                  position="sticky"
                  left={0}
                  zIndex={2}
                  borderRight
                >
                  <Link to={`/organizations/${id}/edit`}>{name}</Link>
                </Table.TextCell>
                {visiblePermissions.map((action) => (
                  <Table.Cell
                    key={action}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    borderRight
                  >
                    {permissionConfig[camelCase(action)] && (
                      <Icon color="success" icon={TickCircleIcon} />
                    )}
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Body>
        </Table>
      </Pane>
    </React.Fragment>
  );
}

OrganizationPermissionsTable.propTypes = {
  organizations: PropTypes.array.isRequired,
};
